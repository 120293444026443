var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "nav-item" }, [
    _c(
      "div",
      {
        staticClass: "nav-link with-group",
        on: {
          click: function($event) {
            _vm.active = !_vm.active
          }
        }
      },
      [
        _vm.icon ? _c("i", { class: _vm.icon + " float-left" }) : _vm._e(),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("i", {
          staticClass: "drop-group",
          class: _vm.active ? "i-chevron-down" : "i-chevron-right",
          staticStyle: { right: "-5px" }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "menu-nav", class: { active: _vm.active } },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }