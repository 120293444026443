<template>
  <li class="nav-item">
    <div class="nav-link with-group" @click="active = !active">
      <i :class="icon + ' float-left'" v-if="icon"></i>
      <span>{{ title }}</span>
      <i
        class="drop-group"
        style="right: -5px"
        :class="active ? 'i-chevron-down' : 'i-chevron-right'"
      ></i>
    </div>

    <ul class="menu-nav" :class="{ active }">
      <slot></slot>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "i-menu"
    },
    to: String,
    title: [String, Number]
  },
  data() {
    return {
      active: false
    };
  },
  mounted() {
    if (this.$el.querySelector(".nav-link.active")) this.active = true;
  }
};
</script>
